import React, { useMemo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewVisitFormType } from '../schema';
import { Select, Input, Loader } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { trpc } from '../../../app/trpc';

export default function NewVisitTreatmentReason() {
  const {
    control,
    formState: { errors },
  } = useFormContext<NewVisitFormType>();
  const treatmentReasons = trpc.general.getTreatmentReasons.useQuery();
  const { t } = useTranslation('Custom');

  const treatmentReasonOptions = useMemo(() => {
    if (!treatmentReasons.data) {
      return [];
    }

    const sortedReasons = [...treatmentReasons.data];
    const screening = sortedReasons.find(reason => reason.treatment_reason_public_code === 17);

    if (screening) {
      sortedReasons.splice(sortedReasons.indexOf(screening), 1);
      sortedReasons.unshift(screening);
    }

    return sortedReasons.map(({ treatment_reason_public_code, treatment_reason_name }) => ({
      value: String(treatment_reason_public_code),
      label: treatment_reason_name,
    }));
  }, [treatmentReasons.data]);

  return (
    <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
      <Input.Label
        className="sm:w-1/3"
        required
      >
        {t('Treatment reason')}
      </Input.Label>

      <Controller
        name="treatment_reason_code"
        control={control}
        render={({ field }) => (
          <Select
            {...field}
            data={treatmentReasonOptions}
            error={errors.treatment_reason_code?.message}
            className="sm:w-2/3"
            icon={
              treatmentReasons.isInitialLoading ? (
                <Loader
                  size="xs"
                  variant="dots"
                />
              ) : null
            }
            searchable
          />
        )}
      />
    </div>
  );
}
