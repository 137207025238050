// External
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from '@ohif/i18n';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import Compose from './routes/Mode/Compose';
import './App.css';
import {
  ExtensionManager,
  CommandsManager,
  HotkeysManager,
  ServiceProvidersManager,
} from '@ohif/core';
import {
  DialogProvider,
  Modal,
  ModalProvider,
  SnackbarProvider,
  ThemeWrapper,
  ViewportDialogProvider,
  ViewportGridProvider,
  CineProvider,
  UserAuthenticationProvider,
  ToolboxProvider,
} from '@ohif/ui';
// Viewer Project
// TODO: Should this influence study list?
import { AppConfigProvider } from '@state';
import createRoutes from './routes';
import appInit from './appInit.js';
import OpenIdConnectRoutes from './utils/OpenIdConnectRoutes';
import { Provider } from 'react-redux';
import store from './store';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { MantineProvider } from '@mantine/core';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { trpc } from '../trpc';
import { httpLink } from '@trpc/client';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import 'dayjs/locale/ru';
import UserService from './userService';
dayjs.extend(customParseFormat);

let commandsManager: CommandsManager,
  extensionManager: ExtensionManager,
  servicesManager: AppTypes.ServicesManager,
  serviceProvidersManager: ServiceProvidersManager,
  hotkeysManager: HotkeysManager;

const queryClient = new QueryClient();

function App({ config, defaultExtensions, defaultModes }) {
  const [init, setInit] = useState(null);
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        httpLink({
          // url: `http://localhost:31/trpc`,
          url: `${window.origin}/api-v2/trpc`,
          async headers() {
            try {
              await UserService.updateToken();
            } catch (e) {
              UserService.doLogin();
            }

            return {
              authorization: `Bearer ${UserService.getToken()}`,
            };
          },
        }),
      ],
    })
  );

  useEffect(() => {
    const run = async () => {
      UserService.initKeycloak(() => {
        appInit(config, defaultExtensions, defaultModes).then(setInit).catch(console.error);
      });
    };

    run();
  }, []);

  if (!init) {
    return null;
  }

  // Set above for named export
  commandsManager = init.commandsManager;
  extensionManager = init.extensionManager;
  servicesManager = init.servicesManager;
  serviceProvidersManager = init.serviceProvidersManager;
  hotkeysManager = init.hotkeysManager;

  // Set appConfig
  const appConfigState = init.appConfig;
  const { routerBasename, modes, dataSources, oidc, showStudyList } = appConfigState;

  // get the maximum 3D texture size
  const canvas = document.createElement('canvas');
  const gl = canvas.getContext('webgl2');

  const max3DTextureSize = gl.getParameter(gl.MAX_3D_TEXTURE_SIZE);
  appConfigState.max3DTextureSize = max3DTextureSize;

  const {
    uiDialogService,
    uiModalService,
    uiNotificationService,
    uiViewportDialogService,
    viewportGridService,
    cineService,
    userAuthenticationService,
    customizationService,
  } = servicesManager.services;

  const providers = [
    [trpc.Provider, { client: trpcClient, queryClient: queryClient }],
    [QueryClientProvider, { client: queryClient }],
    [
      MantineProvider,
      {
        theme: {
          colorScheme: 'dark',
          components: {
            Button: {
              variants: {
                filled: theme => ({
                  root: {
                    backgroundColor: '#1971c2 !important;',
                    ...theme.fn.hover({ backgroundColor: '#1864ab !important;' }),
                    '&:disabled': {
                      opacity: '0.5',
                      color: '#fff',
                    },
                  },
                }),
                outline: theme => ({
                  root: {
                    ...theme.fn.hover({ backgroundColor: 'rgb(51 154 240 / 19%) !important;' }),
                    '&:disabled': {
                      backgroundColor: 'transparent',
                      border: '0.0625rem solid #339af0',
                      color: '#339af0',
                      opacity: '0.5',
                    },
                  },
                }),
              },
            },
            ActionIcon: {
              variants: {
                filled: theme => ({
                  root: {
                    backgroundColor: '#1971c2 !important;',
                    ...theme.fn.hover({ backgroundColor: '#1864ab !important;' }),
                  },
                }),
              },
            },
            Input: {
              styles: {
                input: {
                  backgroundColor: '#000',
                  border: '1px solid rgb(9, 68, 179)',
                  '&:focus': {
                    border: '1px solid #fff',
                  },
                  '&:hover': {
                    border: '1px solid #fff',
                  },
                  '&::placeholder': {
                    color: '#ffffff73',
                  },
                  color: '#fff',
                  // '&:disabled': {
                  //   backgroundColor: '#000',
                  // },
                },
              },
            },
            InputWrapper: {
              styles: {
                label: {
                  color: '#fff',
                },
              },
            },
            Menu: {
              styles: {
                item: {
                  color: '#fff',
                },
              },
            },
            Radio: {
              styles: {
                radio: {
                  backgroundColor: '#000',
                  border: '1px solid rgb(9, 68, 179)',
                  // backgroundColor: '#fff',
                  '&:disabled': {
                    backgroundColor: '#000',
                    opacity: '0.5',
                    '+ .___ref-icon': {
                      color: '#fff',
                    },
                    '&:checked': {
                      backgroundColor: '#1971c2',
                      '+ .___ref-icon': {
                        opacity: '0.5',
                      },
                    },
                  },
                  '&:checked': {
                    backgroundColor: '#1971c2',
                  },
                },
                label: {
                  color: '#fff',
                },
                body: {
                  cursor: 'pointer',
                },
              },
            },
            Checkbox: {
              styles: {
                input: {
                  backgroundColor: '#000',
                  border: '1px solid rgb(9, 68, 179)',
                  '&:disabled': {
                    backgroundColor: '#000',
                    '+ .___ref-icon': {
                      color: '#fff',
                    },
                    '&:checked': {
                      backgroundColor: '#1971c2',
                      '+ .___ref-icon': {
                        opacity: '0.5',
                      },
                    },
                  },
                },
                label: {
                  color: '#fff',
                },
              },
            },
            Title: {
              styles: {
                root: {
                  color: '#fff',
                },
              },
            },
            Notification: {
              styles: {
                root: {
                  backgroundColor: 'rgb(233, 236, 239)',
                },
                description: {
                  color: 'rgb(33, 37, 41)',
                },
                closeButton: {
                  color: 'rgb(73, 80, 87)',
                  '&:hover': {
                    backgroundColor: 'rgb(233, 236, 239)',
                  },
                },
              },
            },
            Modal: {
              styles: {
                content: {
                  backgroundColor: 'rgb(9, 12, 41)',
                },
                header: {
                  backgroundColor: 'rgb(4, 28, 74)',
                },
              },
            },
            Select: {
              styles: {
                item: {
                  color: '#fff',
                },
                input: {
                  '&:disabled': {
                    backgroundColor: '#000',
                  },
                },
              },
            },
            Skeleton: {
              styles: {
                root: {
                  position: 'relative',
                  zIndex: 1,
                },
              },
            },
            DatePickerInput: {
              styles: {
                wrapper: { backgroundColor: '#000' },
              },
            },
          },
        },
        withGlobalStyles: true,
        withNormalizeCSS: true,
      },
    ],
    [DatesProvider, { settings: { locale: 'ru' } }],
    [ModalsProvider],
    [Provider, { store: store }],
    [AppConfigProvider, { value: appConfigState }],
    [UserAuthenticationProvider, { service: userAuthenticationService }],
    [I18nextProvider, { i18n }],
    [ThemeWrapper],
    [ToolboxProvider],
    [ViewportGridProvider, { service: viewportGridService }],
    [ViewportDialogProvider, { service: uiViewportDialogService }],
    [CineProvider, { service: cineService }],
    [SnackbarProvider, { service: uiNotificationService }],
    [DialogProvider, { service: uiDialogService }],
    [ModalProvider, { service: uiModalService, modal: Modal }],
  ];

  // Loop through and register each of the service providers registered with the ServiceProvidersManager.
  const providersFromManager = Object.entries(serviceProvidersManager.providers);
  if (providersFromManager.length > 0) {
    providersFromManager.forEach(([serviceName, provider]) => {
      providers.push([provider, { service: servicesManager.services[serviceName] }]);
    });
  }

  const CombinedProviders = ({ children }) => Compose({ components: providers, children });

  let authRoutes = null;

  // Should there be a generic call to init on the extension manager?
  customizationService.init(extensionManager);

  // Use config to create routes
  const appRoutes = createRoutes({
    modes,
    dataSources,
    extensionManager,
    servicesManager,
    commandsManager,
    hotkeysManager,
    routerBasename,
    showStudyList,
  });

  if (oidc) {
    authRoutes = (
      <OpenIdConnectRoutes
        oidc={oidc}
        routerBasename={routerBasename}
        userAuthenticationService={userAuthenticationService}
      />
    );
  }

  return (
    <CombinedProviders>
      <Notifications position="bottom-left" />
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      <BrowserRouter basename={routerBasename}>
        {authRoutes}
        {appRoutes}
      </BrowserRouter>
    </CombinedProviders>
  );
}

App.propTypes = {
  config: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      routerBasename: PropTypes.string.isRequired,
      oidc: PropTypes.array,
      whiteLabeling: PropTypes.object,
      extensions: PropTypes.array,
    }),
  ]).isRequired,
  /* Extensions that are "bundled" or "baked-in" to the application.
   * These would be provided at build time as part of they entry point. */
  defaultExtensions: PropTypes.array,
};

App.defaultProps = {
  config: {
    /**
     * Relative route from domain root that OHIF instance is installed at.
     * For example:
     *
     * Hosted at: https://ohif.org/where-i-host-the/viewer/
     * Value: `/where-i-host-the/viewer/`
     * */
    routerBaseName: '/',
    /**
     *
     */
    showLoadingIndicator: true,
    showStudyList: true,
    oidc: [],
    extensions: [],
  },
  defaultExtensions: [],
};

export default App;

export { commandsManager, extensionManager, servicesManager };
