import axios from 'axios';
import UserService from '../userService';

export const axiosClient = axios.create({
  baseURL: `${process.env.MR_BACKEND}`,
});

axiosClient.interceptors.request.use(async config => {
  try {
    await UserService.updateToken();
  } catch (e) {
    console.log(e);
    UserService.doLogin();
  }

  config.headers.authorization = `Bearer ${UserService.getToken()}`;

  return config;
});

axiosClient.interceptors.response.use(
  config => {
    return config;
  },
  async error => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        await UserService.updateToken();
        return axiosClient.request(originalRequest);
      } catch (e) {
        console.log(e);
        UserService.doLogin();
      }
    }
    throw error;
  }
);

export const orthancAxios = axios.create({
  baseURL: `${process.env.MR_OLD_URL}/pacs`,
});

orthancAxios.interceptors.request.use(config => {
  config.headers.authorization = `Basic ${btoa(
    process.env.ORTHANC_LOGIN + ':' + process.env.ORTHANC_PASSWORD
  )}`;

  return config;
});
