import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewVisitFormType } from '../../schema';
import Drawer from '../../../Drawer';
import { useGetMammoInstancesCount } from '../../../../app/src/api/orthanc';
import { Textarea, Text, Button, Input } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { Studies } from 'platform/app/src/types/StudyTypes';
import { useTranslation } from 'react-i18next';

export default function MammoVisitAssistant({ study }: { study?: Studies }) {
  const {
    control,
    formState: { errors },
    setValue,
    register,
  } = useFormContext<NewVisitFormType>();
  const mq = study?.mammo_questionnaire[0];
  const { t } = useTranslation('Custom');

  const instancesCount = useGetMammoInstancesCount(
    { ohif_id: study?.ohif_id! },
    { enabled: false, cacheTime: 0 }
  );

  useEffect(() => {
    if (instancesCount.data) {
      setValue('direct_projection.right', instancesCount.data?.['R-CC']?.toString() || '');
      setValue('direct_projection.left', instancesCount.data?.['L-CC']?.toString() || '');
      setValue('oblique_projection.right', instancesCount.data?.['R-MLO']?.toString() || '');
      setValue('oblique_projection.left', instancesCount.data?.['L-MLO']?.toString() || '');

      const total =
        (instancesCount.data?.['R-CC'] || 0) +
        (instancesCount.data?.['L-CC'] || 0) +
        (instancesCount.data?.['R-MLO'] || 0) +
        (instancesCount.data?.['L-MLO'] || 0);

      setValue('mammo_total_number', String(total || ''));
    }
  }, [instancesCount.data, setValue, mq]);

  const fieldBlocks = [
    {
      name: 'direct_projection' as const,
      label: `${t('Direct')} (CC)`,
    },
    {
      name: 'oblique_projection' as const,
      label: `${t('Oblique')} (OBL)`,
    },
  ];

  return (
    <>
      <fieldset className="mt-12 border p-4">
        <legend className="px-4 font-bold">{t('Assistant')}</legend>

        <div className="flex justify-between gap-4">
          <div className="w-1/2">
            <p>{t('Note')}</p>
            <Textarea
              {...register('assistant_note')}
              placeholder={t(
                'Describe the skin formations and the results of an objective examination'
              )}
              error={errors.assistant_note?.message}
            />
          </div>
          <div className="w-1/2">
            <Drawer
              previewImg={
                study?.mammo_questionnaire[0]?.assistant_pic
                  ? `${process.env.S3_URL}/${study.mammo_questionnaire[0].assistant_pic}`
                  : null
              }
              bgImg="/assets/app/R_L.png"
              onSave={urlData => setValue('assistant_pic', urlData)}
            />
          </div>
        </div>

        <Text className="font-bold">{t('Mammogram projections and their number')}</Text>
        <Text className="font-bold">{t('The number of repeated mammograms')}</Text>

        <div className="mb-1 mt-2 flex items-center gap-3">
          <div className="w-1/2">
            {study?.ohif_id && (
              <p className="w-24 text-center">
                <Button
                  variant="outline"
                  loading={instancesCount.isInitialLoading}
                  onClick={() => instancesCount.refetch()}
                >
                  {t('Detect automatically')}
                </Button>
              </p>
            )}
          </div>
          <div className="w-1/2">
            <div className="flex gap-2">
              <p className="w-24 text-center">R</p>
              <p className="w-24 text-center">L</p>
            </div>
          </div>
        </div>

        {fieldBlocks.map(fieldBlock => {
          return (
            <div
              className="mb-3 flex items-center gap-3"
              key={fieldBlock.name}
            >
              <div className="w-1/2">{fieldBlock.label}</div>
              <div className="w-1/2">
                <div className="flex gap-2">
                  <div className="w-24">
                    <Input
                      {...register(`${fieldBlock.name}.right`)}
                      type="number"
                      onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                      error={errors[fieldBlock.name]?.right?.message}
                    />
                  </div>
                  <div className="w-24">
                    <Input
                      {...register(`${fieldBlock.name}.left`)}
                      type="number"
                      onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                      error={errors[fieldBlock.name]?.left?.message}
                    />
                  </div>
                </div>
              </div>
            </div>
          );
        })}

        <div className="mb-3 flex items-center gap-3">
          <div className="w-1/2">{t('Total number of mammograms')}</div>
          <div className="w-1/2">
            <Input
              {...register('mammo_total_number')}
              type="number"
              onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
              error={errors.mammo_total_number?.message}
              className="w-[200px]"
            />
          </div>
        </div>

        <div className="mb-3 flex items-center gap-3">
          <div className="w-1/2">{t('Date')}</div>
          <div className="w-1/2">
            <div className="w-[200px]">
              <Controller
                name="assistant_date"
                control={control}
                render={({ field }) => (
                  <DateInput
                    {...field}
                    dateParser={input => dayjs(input, 'DD.MM.YYYY').toDate()}
                    placeholder="ДД.ММ.ГГГГ"
                    size="xs"
                    valueFormat="DD.MM.YYYY"
                    className="w-32"
                    error={errors[field.name]?.message}
                  />
                )}
              />
            </div>
          </div>
        </div>
      </fieldset>
    </>
  );
}
