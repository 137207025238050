import React, { useEffect, useState } from 'react';
import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Loader,
  Modal,
  MultiSelect,
  Pagination,
  Table,
  TextInput,
  Title,
} from '@mantine/core';
import { DateInput } from '@mantine/dates';
import { useDebouncedValue, useDisclosure } from '@mantine/hooks';
import dayjs from 'dayjs';
import { formatDate } from '../../../utils/dateUtils';
import {
  AppointmentsIcon,
  CancelStudyIcon,
  CloudStatus,
  DownloadConclusionIcon,
  EditStudyIcon,
  ServicesIcon,
  SmsStatusIcons,
  RewriteResponsibleUserBtn,
  StudyStatusIcon,
} from './WorkList/AppointmentsIcon';
import { trpc } from '../../trpc';
import { Header } from '@ohif/ui';
import UserService from '../userService';
import { useNavigate, useParams } from 'react-router';
import { modals } from '@mantine/modals';
import NewVisitModal from '../../../components/NewVisitModal';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../medreview/server/src';
import { PanelConclusion } from '../../../../extensions/default/src/Panels';
import { extensionManager } from '../App';
import { useSearchParams } from 'react-router-dom';
import { utils } from '@ohif/core';
import { useAppConfig } from '@state';
import { useTranslation } from 'react-i18next';
import { Studies } from '../types/StudyTypes';
import { getOptimalDataSource } from '../../../utils/orthancUtils';
import { TooltipClipboard } from '@ohif/ui';

const { sortBySeriesDate } = utils;
const PER_PAGE = 25;
export const dbStatusesMap = {
  CREATED: { label: 'Создано', color: 'blue' },
  IN_PROGRESS: { label: 'В процессе', color: 'orange' },
  FINISHED: { label: 'Завершено', color: 'green' },
  CANCELLED: { label: 'Отменено', color: 'red' },
} as const;

export default function DataSourceWrapper() {
  const params = useParams();
  const query = useSearchParams();
  const [isDataSourceInitialized, setIsDataSourceInitialized] = useState(false);
  const dataSource = extensionManager.getActiveDataSource();

  useEffect(() => {
    const initializeDataSource = async () => {
      await dataSource[0].initialize({ params, query });
      setIsDataSourceInitialized(true);
    };

    initializeDataSource();
  }, [dataSource]);

  return isDataSourceInitialized ? <StudyList /> : null;
}

function StudyList() {
  const navigate = useNavigate();
  const [appConfig] = useAppConfig();

  const user = trpc.general.getUser.useQuery();
  const dbServices = trpc.general.getServices.useQuery({
    orgIds: user.data?.organization_user.map(ou => ou.organization_id!),
  });
  const dbResponsibleUsers = trpc.general.getUsers.useQuery({
    orgIds: user.data?.organization_user.map(ou => ou.organization_id!),
    isResponsible: true,
  });
  const dbModalities = trpc.general.getModalities.useQuery({
    orgIds: user.data?.organization_user.map(ou => ou.organization_id!),
  });
  const dbPaymentTypes = trpc.general.getPaymentTypes.useQuery();

  const [page, setPage] = useSearchParam('page', '1');
  const [iinOrFio, setIinOrFio] = useSearchParam('iinOrFio');
  const [debouncedIinOrFio] = useDebouncedValue(iinOrFio, 1500);
  const [dateFrom, setDateFrom] = useSearchParam('dateFrom');
  const [dateTo, setDateTo] = useSearchParam('dateTo');
  const [desc, setDesc] = useSearchParam('desc');
  const [debouncedDesc] = useDebouncedValue(desc, 1500);
  const [modalities, setModalities] = useSearchParam('modalities');
  const [services, setServices] = useSearchParam('services');
  const [responsibleUserIds, setResponsibleUserIds] = useSearchParam('responsibleUserIds');
  const [paymentTypeIds, setPaymentTypeIds] = useSearchParam('paymentTypeIds');
  const [statuses, setStatuses] = useSearchParam(
    'statuses',
    Object.keys(dbStatusesMap)
      .filter(ds => ds !== 'CANCELLED')
      .join(',')
  );
  const [orgIds, setOrgIds] = useSearchParam('orgIds');
  const [incorrectIIN, setIncorrectIIN] = useSearchParam('incorrectIIN');
  const [incorrectPhone, setIncorrectPhone] = useSearchParam('incorrectPhone');
  const [assignedToMe, setAssignedToMe] = useSearchParam('assignedToMe');
  const [hasPatientComments, setHasPatientComments] = useSearchParam('hasPatientComments');
  const [waitsSecondReader, setWaitsSecondReader] = useSearchParam('waitsSecondReader');

  const studies = trpc.study.getAll.useQuery({
    skip: (+page! - 1) * PER_PAGE,
    take: PER_PAGE,
    iinOrFio: debouncedIinOrFio || undefined,
    dateFrom: dateFrom ? dayjs(dateFrom).format('YYYYMMDD') : undefined,
    dateTo: dateTo ? dayjs(dateTo).format('YYYYMMDD') : undefined,
    desc: debouncedDesc || undefined,
    modalities: modalities ? modalities.split(',') : undefined,
    services: services ? services.split(',') : undefined,
    responsibleUserIds: responsibleUserIds
      ? responsibleUserIds.split(',').map(id => +id)
      : undefined,
    paymentTypeIds: paymentTypeIds ? paymentTypeIds.split(',').map(id => +id) : undefined,
    statuses: statuses ? statuses.split(',') : undefined,
    orgIds: orgIds ? orgIds.split(',').map(id => +id) : undefined,
    incorrectIIN: !!incorrectIIN || undefined,
    incorrectPhone: !!incorrectPhone || undefined,
    assignedToMe: !!assignedToMe || undefined,
    hasPatientComments: !!hasPatientComments || undefined,
    waitsSecondReader: !!waitsSecondReader || undefined,
  });

  function setDateRange(subtract: number) {
    setDateFrom(dayjs().subtract(subtract, 'day').format('YYYY-MM-DD'));
    setDateTo(dayjs().format('YYYY-MM-DD'));
    setPage('1');
  }

  return (
    <>
      <Header
        isSticky={false}
        menuOptions={[
          {
            title: 'отчеты',
            icon: 'report-page',
            onClick: () => navigate('/report'),
          },
          {
            icon: 'power-off',
            title: 'Выйти',
            onClick: () => UserService.doLogout(),
          },
        ]}
        isReturnEnabled={false}
        WhiteLabeling={appConfig.whiteLabeling}
        showPatientInfo="disabled"
      />

      <Flex className="items-center justify-between p-5">
        <Box>
          <Title
            order={2}
            className="hidden sm:block"
          >
            Список обследований
          </Title>
          {studies.data && (
            <Title
              order={5}
              className="hidden sm:block"
            >
              Количество - {studies.data.count}
            </Title>
          )}
        </Box>

        <Button
          className="mr-3"
          onClick={() =>
            modals.open({
              children: <NewVisitModal />,
              size: 1050,
            })
          }
        >
          Новое посещение
        </Button>
      </Flex>

      <Box className="p-5">
        <Flex className="flex-wrap items-end gap-2">
          <TextInput
            size="xs"
            className="w-full sm:w-44"
            label="ИИН или ФИО"
            value={iinOrFio || ''}
            onChange={e => {
              setIinOrFio(e.target.value || null);
              setPage('1');
            }}
          />

          <Box>
            <Flex className="items-center gap-1">
              <Input.Label>Дата</Input.Label>
              <Badge
                size="xs"
                onClick={() => setDateRange(0)}
                className="cursor-pointer"
              >
                Сегодня
              </Badge>
              <Badge
                size="xs"
                onClick={() => setDateRange(3)}
                className="cursor-pointer"
              >
                3 дня
              </Badge>
              <Badge
                size="xs"
                onClick={() => setDateRange(7)}
                className="cursor-pointer"
              >
                7 дней
              </Badge>
              <Badge
                size="xs"
                onClick={() => setDateRange(30)}
                className="cursor-pointer"
              >
                30 дней
              </Badge>
            </Flex>
            <Flex className="w-full items-end gap-2 sm:w-auto">
              <DateInput
                size="xs"
                clearable
                className="w-1/2 sm:w-[124px]"
                maxDate={dateTo ? new Date(dateTo) : undefined}
                value={dateFrom ? new Date(dateFrom) : null}
                dateParser={input => dayjs(input, 'DD.MM.YYYY').toDate()}
                placeholder="от"
                valueFormat="DD.MM.YYYY"
                onChange={date => {
                  setDateFrom(date ? dayjs(date).format('YYYY-MM-DD') : null);
                  setPage('1');
                }}
              />

              <DateInput
                size="xs"
                clearable
                className="w-1/2 sm:w-[124px]"
                minDate={dateFrom ? new Date(dateFrom) : undefined}
                value={dateTo ? new Date(dateTo) : null}
                dateParser={input => dayjs(input, 'DD.MM.YYYY').toDate()}
                placeholder="до"
                valueFormat="DD.MM.YYYY"
                onChange={date => {
                  setDateTo(date ? dayjs(date).format('YYYY-MM-DD') : null);
                  setPage('1');
                }}
              />
            </Flex>
          </Box>

          <TextInput
            size="xs"
            className="w-full sm:w-44"
            label="Описание"
            value={desc || ''}
            onChange={e => {
              setDesc(e.target.value || null);
              setPage('1');
            }}
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Модальность"
            data={
              dbModalities.data?.map(am => ({
                value: am.name || '',
                label: (am.name === 'MG' ? `${am.name}, DX-Mammo` : am.name) || '',
              })) || []
            }
            value={modalities ? modalities.split(',') : []}
            onChange={value => {
              value.length ? setModalities(value.join(',')) : setModalities(null);
              setPage('1');
            }}
            limit={50}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Услуга"
            data={
              dbServices.data?.map(service => ({
                value: service.service_code || '',
                label: service.short_service_name || service.service_name || '',
              })) || []
            }
            value={services ? services.split(',') : []}
            onChange={value => {
              value.length ? setServices(value.join(',')) : setServices(null);
              setPage('1');
            }}
            limit={50}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Врач-исполнитель"
            data={
              dbResponsibleUsers.data?.map(ru => ({
                value: ru.id.toString(),
                label: ru.fullname || '',
              })) || []
            }
            value={responsibleUserIds ? responsibleUserIds.split(',') : []}
            onChange={value => {
              value.length ? setResponsibleUserIds(value.join(',')) : setResponsibleUserIds(null);
              setPage('1');
            }}
            limit={50}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Оплата"
            data={
              dbPaymentTypes.data?.map(pt => ({
                value: pt.id.toString(),
                label: pt.payment_type_rus || '',
              })) || []
            }
            value={paymentTypeIds ? paymentTypeIds.split(',') : []}
            onChange={value => {
              value.length ? setPaymentTypeIds(value.join(',')) : setPaymentTypeIds(null);
              setPage('1');
            }}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          <MultiSelect
            size="xs"
            className="w-full sm:w-44"
            classNames={{
              values: 'flex-nowrap max-w-xs overflow-hidden',
              itemsWrapper: 'gap-[2px]',
            }}
            label="Статус"
            data={Object.keys(dbStatusesMap).map(status => ({
              value: status,
              label: dbStatusesMap[status as keyof typeof dbStatusesMap].label,
            }))}
            value={statuses ? statuses.split(',') : []}
            onChange={value => {
              value.length ? setStatuses(value.join(',')) : setStatuses(null);
              setPage('1');
            }}
            searchable
            clearable
            disableSelectedItemFiltering
          />

          {user.data.organization_user.length > 1 && (
            <MultiSelect
              size="xs"
              className="w-full sm:w-44"
              classNames={{
                values: 'flex-nowrap max-w-xs overflow-hidden',
                itemsWrapper: 'gap-[2px]',
              }}
              label="Организация"
              data={user.data.organization_user.map(ou => ({
                value: ou.organization_id.toString(),
                label: ou.organizations.name,
              }))}
              value={orgIds ? orgIds.split(',') : []}
              onChange={value => {
                value.length ? setOrgIds(value.join(',')) : setOrgIds(null);
                setPage('1');
              }}
              searchable
              clearable
              disableSelectedItemFiltering
            />
          )}

          <Flex className="flex-wrap items-end gap-2">
            <Flex className="flex-col flex-wrap gap-1">
              <Checkbox
                size="xs"
                label="Некорректный ИИН"
                checked={!!incorrectIIN}
                onChange={e => {
                  setIncorrectIIN(e.currentTarget.checked ? 'true' : null);
                  setPage('1');
                }}
              />
              <Checkbox
                size="xs"
                label="Некорректный телефон"
                checked={!!incorrectPhone}
                onChange={e => {
                  setIncorrectPhone(e.currentTarget.checked ? 'true' : null);
                  setPage('1');
                }}
              />
            </Flex>

            <Flex className="flex-col gap-1">
              <Checkbox
                size="xs"
                label="Назначены мне"
                checked={!!assignedToMe}
                onChange={e => {
                  setAssignedToMe(e.currentTarget.checked ? 'true' : null);
                  setPage('1');
                }}
              />

              <Checkbox
                size="xs"
                label="Военкомат"
                checked={!!hasPatientComments}
                onChange={e => {
                  setHasPatientComments(e.currentTarget.checked ? 'true' : null);
                  setPage('1');
                }}
              />
            </Flex>

            <Flex className="flex-col gap-1 self-center">
              <Checkbox
                size="xs"
                label="Ожидают второго чтения"
                checked={!!waitsSecondReader}
                onChange={e => {
                  setWaitsSecondReader(e.currentTarget.checked ? 'true' : null);
                  setPage('1');
                }}
              />
            </Flex>
          </Flex>
          <Button
            size="xs"
            onClick={() => {
              setIinOrFio(null);
              setDateFrom(null);
              setDateTo(null);
              setDesc(null);
              setModalities(null);
              setServices(null);
              setResponsibleUserIds(null);
              setPaymentTypeIds(null);
              setStatuses(null);
              setOrgIds(null);
              setIncorrectIIN(null);
              setIncorrectPhone(null);
              setAssignedToMe(null);
              setHasPatientComments(null);
              setWaitsSecondReader(null);
              setPage('1');
            }}
            variant="outline"
          >
            Очистить фильтры
          </Button>
        </Flex>

        {studies.isLoading && (
          <Loader
            variant="dots"
            className="mx-auto my-12"
          />
        )}

        {!studies.isLoading && (
          <Table className="mt-12">
            <thead>
              <tr className="hover:bg-secondary-main bg-primary-dark cursor-pointer transition duration-300">
                <th className={`${tdStyle}`}>ИИН</th>
                <th className={`${tdStyle} hidden md:table-cell`}>Фио</th>
                <th className={`${tdStyle}`}>Дата</th>
                <th className={`${tdStyle} hidden md:table-cell`}>Описание</th>
                <th className={`${tdStyle} hidden md:table-cell`}>Модальность</th>
                <th className={`${tdStyle} hidden xl:table-cell`}>Услуга</th>
                <th className={`${tdStyle} hidden lg:table-cell`}>Врач-исполнитель</th>
                <th className={`${tdStyle} hidden lg:table-cell`}>Оплата</th>
                <th className={`${tdStyle}`}>Статусы</th>
              </tr>
            </thead>
            <tbody>
              {studies.data?.data.map(studyItem => (
                <Study
                  studyItem={studyItem}
                  key={studyItem.id}
                />
              ))}
            </tbody>
          </Table>
        )}

        <Pagination
          className="mt-8 justify-end"
          value={page ? +page : 1}
          onChange={p => setPage(p.toString())}
          total={Math.ceil((studies.data?.count || PER_PAGE) / PER_PAGE)}
        />
      </Box>
    </>
  );
}

function Study({
  studyItem,
}: {
  studyItem: NonNullable<inferRouterOutputs<AppRouter>['study']['getAll']['data'][number]>;
}) {
  const [opened, { open, close }] = useDisclosure(false);
  const modality_names = studyItem.modality_study.map(ms => ms.modalities.name);

  const study = {
    ...studyItem,
    is_mammo: modality_names.includes('MG'),
    modalities: modality_names.join('\\') || '',
  };

  const studyDate = formatDate(study.date, 'DD.MM.YYYY', ['YYYYMMDD', 'YYYY.MM.DD']);
  // const studyTime = formatDate(study.time, 'HH:mm', ['HHmmss.SSS','HHmmss','HHmm','HH']);

  return (
    <React.Fragment>
      <tr
        className="hover:bg-secondary-main bg-primary-dark border-secondary-light cursor-pointer border-r border-l transition duration-300"
        onClick={() => (opened ? close() : open())}
      >
        <td className={`${tdStyle}`}>
          <p>
            <TooltipClipboard>{study.patients?.iin || ''}</TooltipClipboard>
          </p>
          <p className="block md:hidden">
            <TooltipClipboard>{study.patients?.fullname || ''}</TooltipClipboard>
          </p>
        </td>
        <td className={`${tdStyle} hidden md:table-cell`}>
          <TooltipClipboard>{study.patients?.fullname || ''}</TooltipClipboard>
        </td>
        <td className={`${tdStyle}`}>
          <TooltipClipboard>{studyDate}</TooltipClipboard>
        </td>
        <td className={`${tdStyle} hidden md:table-cell`}>
          <TooltipClipboard>{study.description || ''}</TooltipClipboard>
        </td>
        <td className={`${tdStyle} hidden md:table-cell`}>
          <TooltipClipboard>
            {study.modality_study
              .map(ms => ms.modality_pacs?.name || ms.modalities.name)
              .join(', ')}
          </TooltipClipboard>
        </td>
        <td className={`${tdStyle} hidden xl:table-cell`}>
          <ServicesIcon study={study} />
        </td>
        <td className={`${tdStyle} hidden lg:table-cell`}>
          <RewriteResponsibleUserBtn study={study} />
        </td>
        <td className={`${tdStyle} hidden lg:table-cell`}>
          <TooltipClipboard>{study.payment_type?.payment_type_rus || ''}</TooltipClipboard>
        </td>
        <td className={`${tdStyle}`}>
          <div
            className="flex flex-wrap gap-1 sm:gap-2"
            onClick={e => e.stopPropagation()}
          >
            <StudyStatusIcon study={study} />
            <SmsStatusIcons study={study} />
            <EditStudyIcon study={study} />
            <AppointmentsIcon study={study} />
            <CloudStatus study={study} />
            {(study.uploaded_to_the_cloud || study.conclusion.length > 0) && (
              <DownloadConclusionIcon study={study} />
            )}
            {(study.status === 'IN_PROGRESS' || study.status === 'CANCELLED') && (
              <CancelStudyIcon study={study} />
            )}
          </div>
        </td>
      </tr>

      {opened && (
        <>
          <tr className="border-secondary-light rounded border">
            <ExpandedRow studyItem={study} />
          </tr>
          <tr>
            <td className="!border-t-0">
              <div className="h-2"></div>
            </td>
          </tr>
        </>
      )}
    </React.Fragment>
  );
}

function ExpandedRow({ studyItem }: { studyItem: Studies }) {
  const [opened, { open, close }] = useDisclosure(false);
  const { t } = useTranslation('StudyList');
  const [series, setSeries] = useState([]);
  const dataSource = extensionManager.getActiveDataSource();
  const [appConfig] = useAppConfig();
  const trpcUtils = trpc.useUtils();

  useEffect(() => {
    const fetchSeries = async () => {
      const optimalDataSource = await getOptimalDataSource(
        studyItem.organizations!.orthanc_config[0] || null,
        [],
        studyItem.ohif_id
      );

      const result = await dataSource[0].query.series.search(
        studyItem.ohif_id!,
        optimalDataSource + '/dicom-web'
      );
      setSeries(sortBySeriesDate(result));
    };

    fetchSeries();
  }, [dataSource, studyItem.ohif_id, studyItem.organizations]);

  return (
    <td colSpan={9}>
      <div className="w-full bg-black py-4 px-12">
        <div className="block">
          <div className="flex flex-row flex-wrap gap-2">
            {appConfig.loadedModes.map(mode => {
              const isValidMode = mode.isValidMode({
                modalities: studyItem.modality_study
                  .map(ms => ms.modality_pacs?.name || ms.modalities.name)
                  .join('\\'),
                study: { mrn: studyItem.patients.iin, studyInstanceUid: studyItem.ohif_id },
              });
              return (
                <Button
                  key={mode.routeName}
                  size="xs"
                  disabled={!isValidMode?.valid}
                  onClick={() => {
                    studyItem.ohif_id || studyItem.is_mammo
                      ? window.open(
                          `${mode.routeName}?StudyInstanceUIDs=${studyItem.ohif_id}${
                            studyItem.is_mammo ? '&hangingprotocolId=mgCC' : ''
                          }`,
                          '_blank'
                        )
                      : open();
                  }}
                  rel="noreferrer"
                >
                  {t(`Modes:${mode.displayName}`)}
                </Button>
              );
            })}
          </div>
        </div>
        <div className="mt-4 hidden sm:block">
          <Table>
            <thead>
              <tr>
                <td>{t('Description')}</td>
                <td>{t('Series')}</td>
                <td>{t('Modality')}</td>
                <td>{t('Instances')}</td>
              </tr>
            </thead>

            <tbody>
              {series.map((row, i) => {
                return (
                  <tr key={i}>
                    <td>{row.description}</td>
                    <td>{row.seriesNumber}</td>
                    <td>{row.modality}</td>
                    <td>{row.numSeriesInstances}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>

        <div onClick={e => e.stopPropagation()}>
          <Modal
            opened={opened}
            onClose={close}
            size={1050}
          >
            <PanelConclusion
              studyId={studyItem.id}
              onSave={() => trpcUtils.study.getAll.invalidate()}
            />
          </Modal>
        </div>
      </div>
    </td>
  );
}

const tdStyle = '!border-secondary-light truncate border-b text-white';

function useSearchParam(key: string, defaultValue?: string) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [isFirstCall, setIsFirstCall] = useState(true);

  function setter(value: string | null) {
    const newParams = new URLSearchParams(window.location.search);
    if (value === null) {
      newParams.delete(key);
    } else {
      newParams.set(key, value);
    }

    setIsFirstCall(false);
    setSearchParams(newParams, {});
  }

  return [searchParams.get(key) || (isFirstCall ? defaultValue : null), setter] as const;
}
