import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewVisitFormType, serviceModalities } from '../schema';
import { Radio, Input, Select } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { trpc } from '../../../app/trpc';

export default function NewVisitModalitySlugsData({ disabled = false }: { disabled?: boolean }) {
  const { register, control } = useFormContext<NewVisitFormType>();
  const { t } = useTranslation('Custom');
  const user = trpc.general.getUser.useQuery();

  return (
    <>
      {user.data.organization_user.length > 1 && (
        <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
          <Input.Label
            className="sm:w-1/3"
            required
          >
            Организация
          </Input.Label>
          <Controller
            name="orgId"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                data={user.data.organization_user.map(ou => ({
                  value: ou.organization_id.toString(),
                  label: ou.organizations.name,
                }))}
                className="sm:w-2/3"
                searchable
                disabled={disabled}
              />
            )}
          />
        </div>
      )}
      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {t('Type of study')}
        </Input.Label>
        <div className="flex flex-wrap gap-4 sm:w-2/3">
          {Object.entries(serviceModalities).map(([serviceName, modality]) => (
            <div key={serviceName}>
              <Radio
                {...register('serviceModality')}
                label={modality.label}
                disabled={disabled}
                value={serviceName}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
