import React, { useMemo, useEffect, ReactNode } from 'react';
import { useFormContext } from 'react-hook-form';
import { serviceDefaultValues, NewVisitFormType, serviceModalities } from '../schema';
import { Table, Button } from '@mantine/core';
import { Select, TextInput, Textarea } from '@mantine/core';
import { Studies } from 'platform/app/src/types/StudyTypes';
import { useTranslation } from 'react-i18next';
import { Decimal } from 'decimal.js';
import { trpc } from '../../../app/trpc';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../../../medreview/server/src';
import { notifications } from '@mantine/notifications';

interface Props {
  study?: Studies;
  appointments: NonNullable<inferRouterOutputs<AppRouter>['visit']['getVisitData']>['appointments'];
}

export default function NewVisitServiceData({ study, appointments }: Props) {
  const {
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<NewVisitFormType>();

  const serviceModality = watch('serviceModality');
  const currentOrgId = watch('orgId');

  const { t } = useTranslation('Custom');

  const { data: filteredServices = [] } = trpc.general.getServices.useQuery(
    {
      modalitySlugs: (serviceModalities[serviceModality]?.modality_slugs as any) || [],
      orgIds: [+currentOrgId],
    },
    { enabled: !!serviceModality }
  );

  useEffect(() => {
    let services = [serviceDefaultValues];

    if (study && filteredServices.length > 0) {
      const studyServices = study.services.map(s => {
        const serviceAppointment = study.appointment.find(
          a =>
            s.price.services?.service_code &&
            a.service_code === s.price.services.service_code &&
            a.study_id === study.id
        );

        const service = {
          id: s.id,
          service_code: s.service_code,
          service_count: s.service_count,
          comment: s.comment || '',
          appointment_id: serviceAppointment?.id,
        };

        return service;
      });

      if (studyServices.length > 0) {
        services = studyServices;
      }

      const predictedService = filteredServices
        .filter(service => service.price?.find(p => p.orgaization_id === study.organization_id))
        .find(service => study.description && service.dcm_description?.includes(study.description));

      if (appointments.length === 0 && predictedService?.service_code && !studyServices.length) {
        notifications.show({
          message: `Сервис "${predictedService.short_service_name}" был выбран автоматически. Вы можете выбрать другой сервис`,
          color: 'orange',
        });

        services = [
          {
            ...serviceDefaultValues,
            service_code: predictedService.service_code,
          },
        ];
      }

      setValue('services', services);
    }
  }, [appointments.length, filteredServices, setValue, study]);

  const services = watch('services');
  const payment_type_slug = watch('payment_type_slug');

  const pricedServices = useMemo(() => {
    return services.map(service => {
      const selectedService = filteredServices.find(s => s.service_code === service.service_code);

      const orId = payment_type_slug !== 'OSMS' ? +currentOrgId : null;

      const selectedServicePrice = selectedService?.price?.find(
        price => price.orgaization_id === orId
      );

      if (!selectedServicePrice && selectedService) {
        notifications.show({
          message: `Для сервиса "${selectedService?.service_name}" отсутствует цена в базе данных. Обратитесь к администратору системы`,
          color: 'red',
        });
      }

      return {
        ...service,
        price: selectedServicePrice?.price,
        service_name: selectedService?.short_service_name || selectedService?.service_name,
      };
    });
  }, [currentOrgId, payment_type_slug, services, filteredServices]);

  return (
    <Table
      withBorder
      className="mt-12"
    >
      <thead>
        <tr>
          <th>{t('Service')}</th>
          <th>{t('Count')}</th>
          <th>{t('Price, tg')}</th>
          <th>{t('Comment')}</th>
        </tr>
      </thead>
      <tbody>
        {pricedServices.length > 0 &&
          pricedServices.map(service => {
            return (
              <>
                <tr key={service.id}>
                  <MobileWrapper
                    className="w-[50%]"
                    title={t('Service')}
                  >
                    {service.appointment_id && (service.service_name || '')}

                    {!service.appointment_id && (
                      <Select
                        value={service.service_code}
                        onChange={value => {
                          if (
                            services.find(selectedService => selectedService.service_code === value)
                          ) {
                            if (!service.service_code) {
                              notifications.show({
                                message: `Данный сервис уже добавлен`,
                                color: 'red',
                              });
                            }

                            return;
                          }

                          setValue(
                            'services',
                            services.map(s =>
                              s.id !== service.id
                                ? s
                                : {
                                    ...service,
                                    service_code: value,
                                  }
                            )
                          );
                        }}
                        data={filteredServices
                          .filter(
                            service => service.price?.find(p => p.orgaization_id === +currentOrgId)
                          )
                          .map(service => ({
                            value: service.service_code || '',
                            label: service.short_service_name || service.service_name || '',
                          }))}
                        searchable
                      />
                    )}
                  </MobileWrapper>

                  <MobileWrapper
                    className="w-[16.66%]"
                    title={t('Count')}
                  >
                    {service.appointment_id && (service.service_count || '')}

                    {!service.appointment_id && (
                      <TextInput
                        value={service.service_count}
                        onChange={e => {
                          setValue(
                            'services',
                            services.map(s =>
                              s.id !== service.id
                                ? s
                                : {
                                    ...service,
                                    service_count: +e.target.value,
                                  }
                            )
                          );
                        }}
                        type="number"
                        onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
                        min="0"
                      />
                    )}
                  </MobileWrapper>

                  <MobileWrapper
                    className="w-[16.66%]"
                    title={t('Price, tg')}
                  >
                    {service.price && service.service_count
                      ? Intl.NumberFormat('ru').format(
                          new Decimal(service.price).mul(service.service_count).toNumber()
                        )
                      : ''}
                  </MobileWrapper>

                  <MobileWrapper
                    className="w-[16.66%]"
                    title={t('Comment')}
                  >
                    <Textarea
                      value={service.comment || ''}
                      onChange={e => {
                        setValue(
                          'services',
                          services.map(s =>
                            s.id !== service.id
                              ? s
                              : {
                                  ...service,
                                  comment: e.target.value,
                                }
                          )
                        );
                      }}
                    />
                  </MobileWrapper>
                </tr>

                {errors.services?.[0]?.service_code?.message && (
                  <p className="ml-2 mb-4 text-xs text-red-500">
                    {errors.services?.[0]?.service_code?.message}
                  </p>
                )}
              </>
            );
          })}

        <tr>
          <td colSpan={4}>
            <Button
              variant="outline"
              onClick={() => {
                setValue('services', [
                  ...services,
                  {
                    ...serviceDefaultValues,
                    id: Math.max(...services.map(s => s.id!)) + 1,
                  },
                ]);
              }}
            >
              {t('Add service')}
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

function MobileWrapper({
  title,
  children,
  className,
}: {
  title: string;
  children: ReactNode;
  className: string;
}) {
  return (
    <>
      <p className="m-1 mb-1 lg:hidden">
        <span className="font-bold">{title}</span>
        {children}
      </p>

      <td className={`hidden lg:table-cell ${className} relative`}>{children}</td>
    </>
  );
}
