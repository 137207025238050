import React, { ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import Dropdown from '../Dropdown';
import HeaderPatientInfo from '../HeaderPatientInfo';
import { PatientInfoVisibility } from '../../types/PatientInfoVisibility';
import { Flex, Switch, Title, Text, Button, Popover } from '@mantine/core';
import { trpc } from '../../../../app/trpc';
import { useGetStudy } from '../../../../app/src/api/studies';
import { useSearchParams } from '../../../../app/src/hooks';
import PatientDetails from '../../../../components/PatientDetails';
import { notifications } from '@mantine/notifications';

function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  showPatientInfo = PatientInfoVisibility.VISIBLE_COLLAPSED,
  servicesManager,
  Secondary,
  appConfig,
  ...props
}: withAppTypes): ReactNode {
  const { t } = useTranslation('Header');
  const [checked, setChecked] = useState(!!localStorage.getItem('localOrthanc'));
  const params = useSearchParams();
  const user = trpc.general.getUser.useQuery();
  const study = useGetStudy();

  // TODO: this should be passed in as a prop instead and the react-router-dom
  // dependency should be dropped
  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };
  async function changeStorage(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.checked) {
      localStorage.removeItem('localOrthanc');
      setChecked(false);
      return;
    }

    if (e.target.checked) {
      setChecked(true);

      const orthancConfig = user.data?.organization_user[0]?.organizations?.orthanc_config[0];

      if (!orthancConfig?.dicom_hostname) {
        notifications.show({
          message: 'Нет доступного локального хранилища',
          color: 'orange',
        });

        setChecked(false);

        return;
      }

      let localOrthancStatus;
      let timeoutId;

      try {
        const controller = new AbortController();
        timeoutId = setTimeout(() => controller.abort(), 5000);

        const response = await fetch(`${orthancConfig.dicom_hostname}/tools/now-local`, {
          method: 'GET',
          signal: controller.signal,
          headers: {
            Authorization: `Basic ${btoa(
              process.env.ORTHANC_LOGIN + ':' + process.env.ORTHANC_PASSWORD
            )}`,
          },
        });

        if (!response.ok) {
          throw new Error();
        }

        localOrthancStatus = 'success';
      } catch (error) {
        localOrthancStatus = 'error';
      } finally {
        clearTimeout(timeoutId);
      }

      if (localOrthancStatus === 'error') {
        notifications.show({
          message:
            'Локальное хранилище снимков недоступно с Вашего устройства. Для устранения проблемы обратитесь к Администратору системы.',
          color: 'orange',
        });

        setChecked(false);

        return;
      }

      localStorage.setItem('localOrthanc', JSON.stringify(orthancConfig));
    }
  }

  function openPrintModal() {
    // UIModalService.show({
    //   content: PrintModal,
    //   contentProps: {
    //     servicesManager,
    //     extensionManager,
    //   },
    //   title: 'Отправить на печать',
    // });
  }
  return (
    <NavBar
      isSticky={isSticky}
      {...props}
    >
      <div className="relative h-[48px] items-center ">
        <div className="absolute left-0 top-1/2 flex -translate-y-1/2 items-center">
          <div
            className={classNames(
              'mr-3 inline-flex items-center',
              isReturnEnabled && 'cursor-pointer'
            )}
            onClick={onClickReturn}
            data-cy="return-to-work-list"
          >
            {isReturnEnabled && (
              <Icon
                name="chevron-left"
                className="text-primary-active w-8"
              />
            )}
            <div className="ml-1 flex items-center gap-1">
              {WhiteLabeling?.createLogoComponentFn?.(React, props) || <Svg name="logo-ohif" />}
              <Title className="mt-1 text-[17px] text-white">MedReview</Title>
            </div>
          </div>
          {study.data?.modality_study.some(ms => ms.modalities.name === 'MG') &&
            params.get('hangingprotocolId') === 'mgCC' && (
              <Button
                className="ml-2 mt-1"
                size="xs"
                onClick={() => {
                  window.open(
                    `/viewer?StudyInstanceUIDs=${study.data?.ohif_id}&hangingprotocolId=mgMLO`,
                    '_blank'
                  );
                }}
              >
                Открыть 2 проекцию
              </Button>
            )}
        </div>
        <div className="absolute top-1/2 left-[250px]  h-8 -translate-y-1/2">{Secondary}</div>
        <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
          <div className="flex items-center justify-center space-x-2">{children}</div>
        </div>
        <div className="absolute right-0 top-1/2 flex -translate-y-1/2 select-none items-center">
          <div className="border-primary-dark mx-1.5 h-[25px] border-r"></div>
          {!children && (
            <div className="mr-2 hidden sm:block lg:mr-20">
              <Flex className="gap-2 text-white">
                <Text>Облачное хранилище снимков</Text>
                <Switch
                  checked={checked}
                  onChange={changeStorage}
                  classNames={{ track: 'bg-[#228be6] border-[#228be6] cursor-pointer' }}
                />
                <Text>Локальное хранилище снимков</Text>
              </Flex>
            </div>
          )}
          <div className="flex-shrink-0">
            <Dropdown
              id="options"
              showDropdownIcon={false}
              list={menuOptions}
              alignment="right"
            >
              <IconButton
                id={'options-settings-icon'}
                variant="text"
                color="inherit"
                size="initial"
                className="text-primary-active hover:bg-primary-dark h-full w-full"
              >
                <Icon name="icon-settings" />
              </IconButton>
            </Dropdown>
          </div>
        </div>
      </div>
    </NavBar>
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  showPatientInfo: PropTypes.string,
  servicesManager: PropTypes.object,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
};

export default Header;
