import React from 'react';
import { trpc } from '../../../../../../../platform/app/trpc';
import { notifications } from '@mantine/notifications';
import { Tooltip, ActionIcon } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';

export default function DownloadPdfBtn({ conclusionId }: { conclusionId?: number }) {
  const { refetch: refetchPdfUrl } = trpc.study.getPdf.useQuery(
    { id: conclusionId as number },
    { enabled: !!conclusionId }
  );

  const downloadPdfHandler = async () => {
    const { data } = await refetchPdfUrl();

    if (data?.conclusion_url) {
      const link = document.createElement('a');
      link.href = `${process.env.S3_URL + '/' + data?.conclusion_url}`;
      link.target = '_blank';
      link.download = 'Заключение.pdf';
      link.dispatchEvent(new MouseEvent('click'));
    } else {
      notifications.show({
        message: 'PDF заключения на стадии генерации, повторите действие через 10 секунд.',
        color: 'orange',
      });
    }
  };
  return (
    <>
      {conclusionId && (
        <ActionIcon
          variant="filled"
          onClick={downloadPdfHandler}
          className="p-[2px]"
        >
          <Tooltip label="Загрузить PDF">
            <IconDownload />
          </Tooltip>
        </ActionIcon>
      )}
    </>
  );
}
