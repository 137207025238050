import dayjs from 'dayjs';
import { Decimal } from 'decimal.js';

export function getMgFramePos(instanceTags: any): 'R-CC' | 'R-MLO' | 'L-CC' | 'L-MLO' | null {
  const viewCode = instanceTags.ViewCodeSequence?.[0]?.CodeValue;
  const laterality =
    instanceTags.ImageLaterality ||
    instanceTags.Laterality ||
    instanceTags.SharedFunctionalGroupsSequence?.[0]?.FrameAnatomySequence?.[0]?.FrameLaterality;

  const typeToCodeMap = {
    CC: 'R-10242',
    MLO: 'R-10226',
  };

  if (laterality === 'R') {
    if (viewCode === typeToCodeMap['CC']) return 'R-CC';
    if (viewCode === typeToCodeMap['MLO']) return 'R-MLO';
  }

  if (laterality === 'L') {
    if (viewCode === typeToCodeMap['CC']) return 'L-CC';
    if (viewCode === typeToCodeMap['MLO']) return 'L-MLO';
  }

  return null;
}

export function getAge(date?: string | Date | null) {
  const dayjsDate = dayjs(date);
  return date && dayjsDate.isValid() ? dayjs().diff(dayjsDate, 'year') : '';
}

/* eslint-disable @typescript-eslint/no-explicit-any */
export function getDicomEntranceDoseInmGy(series: any) {
  if (
    !series?.find(
      (s: any) => s.instances?.some((i: any) => i.EntranceDoseInmGy || i.EntranceDoseInmGy === 0)
    )
  ) {
    return '';
  }

  const res =
    series?.reduce(
      (acc: any, s: any) =>
        new Decimal(acc).plus(
          new Decimal(
            (s.instances || []).reduce(
              (innerAcc: any, i: any) =>
                new Decimal(innerAcc).plus(new Decimal(+i.EntranceDoseInmGy || 0)),
              0
            )
          )
        ),
      0
    ) || 0;

  return new Decimal(res).mul(new Decimal(0.2)).mul(new Decimal(0.12)).toFixed(2).toString();
}
