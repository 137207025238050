import { useEffect, useState } from 'react';
import jszip from 'jszip';
import { saveAs } from 'file-saver';
import { DicomMetadataStore } from '@ohif/core';
import { getEnabledElement } from '@cornerstonejs/core';
import { extensionManager } from '../app/src/App';
import { useAppDispatch } from '../app/src/store';
import { setArchiveStatus } from '../app/src/store/reducers/ArchiveStatusReducer';

function useDownloadArchive(volume, type, downlaodState, studyData) {
  const dispatch = useAppDispatch();

  const dataSource = extensionManager.getActiveDataSource()[0];
  const pacsRoute = dataSource.getConfig().qidoRoot.replace(/\/dicom-web$/, '');

  async function downloadFiles(volume, type) {
    const { viewport } = getEnabledElement(
      document.querySelector('.cornerstone-viewport-element')
    );

    const imageId = viewport.getCurrentImageId();

    const uids = {
      studyUid: imageId.match(/\/studies\/(.+)\/series/m)[1],
      seriesUid: imageId.match(/\/series\/(.+)\/instances/m)[1],
      instanceUid: imageId.match(/\/instances\/(.+)\/frames/m)[1],
    };

    if (type === 'dicom') {
      dispatch(setArchiveStatus({ status: 'loading', type: 'dicom' }));

      await downloadDisomArchive(uids[`${volume}Uid`], pacsRoute);

      dispatch(setArchiveStatus({ status: 'idle', type: null }));
      return;
    }

    const zip = new jszip();
    dispatch(setArchiveStatus({ status: 'loading', type: 'image' }));

    if (volume === 'series') {
      const seriesBlobs = await retrieveSeriesBlobs(
        uids.studyUid,
        uids.seriesUid,
        type
      );

      const rootFolder = zip.folder('/');
      const patientFolder = rootFolder.folder(
        studyData.patients.iin + ' ' + studyData.patients.fullname
      );

      const studyFolder = patientFolder.folder(
        seriesBlobs.accessionNumber.replaceAll('/', '') +
          ' ' +
          seriesBlobs.studyDescription.replaceAll('/', '')
      );

      const seriesFolder = studyFolder.folder(
        seriesBlobs.modality.replaceAll('/', '') +
          ' ' +
          seriesBlobs.seriesDescription.replaceAll('/', '')
      );

      seriesBlobs.blobs.forEach((blob, index) => {
        const zeroRepeat = 6 - String(index).length;
        const fileName =
          seriesBlobs.modality +
          '0'.repeat(zeroRepeat > 0 ? zeroRepeat : 0) +
          index +
          `.${type}`;

        seriesFolder.file(fileName.replaceAll('/', ''), blob, { base64: true });
      });

      rootFolder.generateAsync({ type: 'blob' }).then(function(content) {
        saveAs(
          content,
          `${studyData.patients.iin}_${studyData.patients.fullname}_${studyData.date}.zip`
        );
      });

      dispatch(setArchiveStatus({ status: 'idle', type: null }));

      return;
    }

    if (volume === 'study') {
      const studyBlobs = await retrieveStudyBlobs(
        uids.studyUid,
        uids.seriesUid,
        type
      );

      const rootFolder = zip.folder('/');
      const patientFolder = rootFolder.folder(
        studyData.patients.iin + ' ' + studyData.patients.fullname
      );

      const studyFolder = patientFolder.folder(
        studyBlobs.accessionNumber.replaceAll('/', '') +
          ' ' +
          studyBlobs.studyDescription.replaceAll('/', '')
      );

      studyBlobs.seriesList.forEach((seriesList, index) => {
        const zeroRepeat = 6 - String(index).length;
        const seriesDescription = seriesList.seriesDescription
          ? seriesList.seriesDescription
          : '0'.repeat(zeroRepeat > 0 ? zeroRepeat : 0) + index;

        const folderName = seriesList.modality + ' ' + seriesDescription;

        const currentSeriesFolder = studyFolder.folder(
          folderName.replaceAll('/', '')
        );

        seriesList.blobs.forEach((blob, index) => {
          const zeroRepeat = 6 - String(index).length;
          const fileName =
            seriesList.modality +
            '0'.repeat(zeroRepeat > 0 ? zeroRepeat : 0) +
            index +
            `.${type}`;

          currentSeriesFolder.file(fileName.replaceAll('/', ''), blob, {
            base64: true,
          });
        });
      });

      rootFolder.generateAsync({ type: 'blob' }).then(function(content) {
        saveAs(
          content,
          `${studyData.patients.iin}_${studyData.patients.fullname}_${studyData.date}.zip`
        );
      });

      dispatch(setArchiveStatus({ status: 'idle', type: null }));
    }
  }

  function retrieveStudyBlobs(studyUid, seriesUid, type) {
    return new Promise(async (resolve, reject) => {
      const seriesList = [];

      const study = DicomMetadataStore.getStudy(studyUid);

      study.series.sort((a, b) => a.SeriesNumber - b.SeriesNumber);

      for (const [i] of study.series.entries()) {
        const sortedInstances = study.series[i].instances.sort(
          (a, b) => a.InstanceNumber - b.InstanceNumber
        );

        const imageIds = sortedInstances.map(
          metaData => metaData.SOPInstanceUID
        );

        const blobs = await retrieveBlobs(imageIds, type);

        seriesList.push({
          modality: study.series[i].Modality || 'unknown',
          seriesDescription: study.series[i].SeriesDescription || 'series',
          blobs: blobs || [],
        });

        if (i === study.series.length - 1) {
          resolve({
            patientId: study.PatientID || 'noId',
            accessionNumber: study.AccessionNumber || 'unknown',
            patientName: study.PatientName || 'unknown',
            studyDescription: study.StudyDescription || 'study',
            seriesList: seriesList,
          });
        }
      }
    });
  }

  function retrieveSeriesBlobs(studyUid, seriesUid, type) {
    return new Promise(async (resolve, reject) => {
      const study = DicomMetadataStore.getStudy(studyUid);
      const currentSeries = study.series.find(
        s => s.SeriesInstanceUID === seriesUid
      );

      const sortedInstances = currentSeries.instances.sort(
        (a, b) => a.InstanceNumber - b.InstanceNumber
      );

      const imageIds = sortedInstances.map(metaData => metaData.SOPInstanceUID);

      const blobs = await retrieveBlobs(imageIds, type);

      resolve({
        modality: currentSeries.Modality || 'unknown',
        patientId: study.PatientID || 'unknown',
        accessionNumber: study.AccessionNumber || 'unknown',
        patientName: study.PatientName || 'unknown',
        studyDescription: study.StudyDescription || 'study',
        seriesDescription: currentSeries.SeriesDescription || 'series',
        blobs: blobs || [],
      });
    });
  }

  function retrieveBlobs(uids, type) {
    const blobs = [];

    const headers = {
      Authorization: `Basic ${btoa(
        process.env.ORTHANC_LOGIN + ':' + process.env.ORTHANC_PASSWORD
      )}`,
    };

    return new Promise(async (resolve, reject) => {
      for (const [i] of uids.entries()) {
        const response = await fetch(`${pacsRoute}/tools/lookup`, {
          method: 'POST',
          body: uids[i],
          headers: headers,
        });

        const data = await response.json();

        const numberOfFramesRequest = await fetch(
          `${pacsRoute}/instances/${data[0].ID}`,
          {
            method: 'GET',
            headers: headers,
          }
        );

        const numberOfFramesData = await numberOfFramesRequest.json();
        let numberOfFrames =
          numberOfFramesData.MainDicomTags.NumberOfFrames || 1;

        for (let i = 0; i < numberOfFrames; i++) {
          if (type === 'jpeg') {
            headers.Accept = 'image/jpeg';
          }

          const blobResponce = await fetch(
            `${pacsRoute}/instances/${data[0].ID}/frames/${i}/preview`,
            {
              method: 'GET',
              headers: headers,
            }
          );

          const blob = await blobResponce.blob();
          blobs.push(blob);
        }

        if (i === uids.length - 1) {
          resolve(blobs);
        }
      }
    });
  }

  useEffect(() => {
    if (downlaodState === 'start' && volume && type) {
      downloadFiles(volume, type);
    }
  }, [volume, type, downlaodState]);
}

export async function downloadDisomArchive(uid, pacsRoute) {
  const pacsHost = pacsRoute.replace(/https?:\/\//, '');

  return fetch(`${pacsRoute}/tools/lookup`, {
    method: 'POST',
    body: uid,
    headers: {
      Authorization: `Basic ${btoa(
        process.env.ORTHANC_LOGIN + ':' + process.env.ORTHANC_PASSWORD
      )}`,
    },
  })
    .then(res => res.json())
    .then(res => {
      if (navigator.userAgent.indexOf('Mac') != -1) {
        window.open(`${pacsRoute}/studies/${res[0].ID}/archive`, '_blank');
      } else {
        window.open(
          `https://${process.env.ORTHANC_LOGIN}:${process.env.ORTHANC_PASSWORD}@${pacsHost}/studies/${res[0].ID}/archive`,
          '_blank'
        );
      }
    });
}

export default useDownloadArchive;
