import React, { useMemo, useState } from 'react';
import { useForm, FormProvider, useFormContext, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import CnAttachments from './components/CnAttachments';
import DownloadPdfBtn from './components/btns/DownloadPdfBtn';
import { appendAttachmentsBase } from './utils';
import { useGetStudy } from '../../../../../platform/app/src/api/studies';
import CnMammoRecs from './components/CnMammoRecs';
import NewVisitModal from '../../../../../platform/components/NewVisitModal';
import Drawer from '../../../../../platform/components/Drawer';
import { trpc } from '../../../../../platform/app/trpc';
import { modals } from '@mantine/modals';
import { Tabs, Textarea, Button, TextInput, Select, Radio, Flex, Box } from '@mantine/core';
import { SubmitMammoPaidBtn } from './components/btns/SubmitCnBtn';
import { notifications } from '@mantine/notifications';
import { DicomMetadataStore } from '@ohif/core';
import CnServices from './components/CnServices';
import { biradsToRiskCancerMap, mapMgQuality, xRayDensityGlandMap } from '../../../../../platform/constants';
import { useGetBiradsLocs } from '../../../../../platform/hooks/useGetBiradsLocs';
import { z } from 'zod';
import { getDicomEntranceDoseInmGy } from '../../../../../platform/utils/utils';
import { SelectTemplateBtn } from '../../../../../platform/components/Templates';

export default function CnMammoPaid({ isConclusionOnlyPage }: { isConclusionOnlyPage: boolean }) {
  const user = trpc.general.getUser.useQuery();
  const study = useGetStudy();

  return (
    <div className="mt-3 pl-5 pr-8 pb-5">
      <Tabs
        defaultValue={
          user.data?.roleHelper.isFirstReader
            ? 'FIRST_READING_PAID'
            : user.data?.roleHelper.isSecondReader
            ? 'SECOND_READING_PAID'
            : 'VISIT'
        }
      >
        <Tabs.List>
          <Tabs.Tab value="VISIT">Анкета</Tabs.Tab>
          <Tabs.Tab value="FIRST_READING_PAID">Первое чтение</Tabs.Tab>
          <Tabs.Tab value="SECOND_READING_PAID">Второе чтение</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="VISIT">
          {study.data && (
            <NewVisitModal
              study={{ ...study.data, is_mammo: true, modalities: '' }}
              showPartial="mammo"
            />
          )}
        </Tabs.Panel>

        <Tabs.Panel value="FIRST_READING_PAID">
          <Form
            isConclusionOnlyPage={isConclusionOnlyPage}
            tab="FIRST_READING_PAID"
          />
        </Tabs.Panel>
        <Tabs.Panel value="SECOND_READING_PAID">
          <Form
            isConclusionOnlyPage={isConclusionOnlyPage}
            tab="SECOND_READING_PAID"
          />
        </Tabs.Panel>
      </Tabs>
    </div>
  );
}

function Form({
  tab,
  isConclusionOnlyPage,
}: {
  tab: 'FIRST_READING_PAID' | 'SECOND_READING_PAID';
  isConclusionOnlyPage: boolean;
}) {
  const [wantsToEdit, setWantsToEdit] = useState(false);
  const user = trpc.general.getUser.useQuery();
  const study = useGetStudy();
  const currentStudyUid = new URLSearchParams(window.location.search).get('StudyInstanceUIDs');
  const studyMetadata: any = DicomMetadataStore.getStudy(currentStudyUid);

  const dicomEntranceDoseInmGy = useMemo(
    () => getDicomEntranceDoseInmGy(studyMetadata?.series),
    [studyMetadata?.series]
  );

  const cn = study.data?.conclusion.find(c => c.type === tab);

  const f = useForm<CnMammoPaidForm>({
    resolver: zodResolver(schema),
    defaultValues: {
      study_service_ids: cn?.service_study.map(ss => ss.id.toString()) || [],
      riskFactors: cn?.risk_factors || '',
      entranceDoseInmGy: cn ? cn?.entrance_dose_in_mgy || '' : dicomEntranceDoseInmGy,
      additionalExam: cn?.additional_exam || '',
      xRayDensityGland: cn?.x_ray_density_gland || undefined,
      mgQuality: {
        right: cn?.mg_quality?.right || 'GOOD',
        left: cn?.mg_quality?.left || 'GOOD',
      } as const,
      biradsRight: String(cn?.birads_right?.id || ''),
      biradsLeft: String(cn?.birads_left?.id || ''),
      riskCancer: {
        right: cn?.risk_cancer?.right || undefined,
        left: cn?.risk_cancer?.left || undefined,
      },
      desc: cn?.desc || '',
      attachments:
        cn?.conclusion_images.map(ci => ({
          url: ci.image_path || '',
          type: 'EXISTING' as const,
        })) || [],
      mammoPic: '',
      note: cn?.note || '',
      conclusionPlain: cn?.conclusion_plain || '',
      recs: cn?.recs?.split(' ||| ') || [],
    },
  });

  const createMammo = trpc.mammoPaid.create.useMutation({
    onSettled: async (_, error) => {
      notifications?.show({
        message: error
          ? 'Произошла ошибка при сохранении заключения. Обратитесь к администратору системы для решения проблемы'
          : 'Заключение успешно сохранено',
        color: error ? 'red' : 'green',
        autoClose: 5000,
      });

      if (!error) {
        f.setValue('mammoPic', '');
        await study.refetch();
      }

      modals.closeAll();
      setWantsToEdit(false);
    },
  });

  async function onSubmit(data: CnMammoPaidForm, sendMessage: boolean) {
    const a = await appendAttachmentsBase(data['attachments']);

    createMammo.mutate({
      studyId: study.data!.id,
      sendMessage,

      studyServiceIds: data.study_service_ids.map(s => +s!),
      riskFactors: data.riskFactors || null,
      entranceDoseInmGy: data.entranceDoseInmGy || null,
      additionalExam: data.additionalExam || null,
      xRayDensityGland: data.xRayDensityGland || null,
      mgQuality: {
        right: data.mgQuality.right || null,
        left: data.mgQuality.left || null,
      },
      biradsRight: data.biradsRight ? +data.biradsRight : null,
      biradsLeft: data.biradsLeft ? +data.biradsLeft : null,
      riskCancer: {
        right: data.riskCancer.right || null,
        left: data.riskCancer.left || null,
      },
      desc: data.desc || null,
      attachments: a,
      existingAttachments: data['attachments'].filter(a => a.type === 'EXISTING').map(a => a.url),
      mammoPic: data.mammoPic ? data.mammoPic.substring(data.mammoPic.indexOf(',') + 1) : null,
      note: data.note || null,
      conclusionPlain: data.conclusionPlain || null,
      recs: data.recs.length ? data.recs.join(' ||| ') : null,
    });
  }

  const access = useMemo(() => {
    const allowedRole =
      tab === 'FIRST_READING_PAID' ? 'first_reader_rentgenolog' : 'second_reader_rentgenolog';

    const canEdit =
      user.data?.canMakeConclusions &&
      user.data?.role_user.some(ru => ru.roles?.slug === allowedRole) &&
      allowedRole === 'second_reader_rentgenolog'
        ? !!study.data?.conclusion.find(cn => cn.type === 'FIRST_READING_PAID')
        : true;

    return { canEdit, disabled: !canEdit || createMammo.isLoading || (!!cn && !wantsToEdit) };
  }, [
    cn,
    createMammo.isLoading,
    study.data?.conclusion,
    tab,
    user.data?.canMakeConclusions,
    user.data?.role_user,
    wantsToEdit,
  ]);

  return (
    <Box component="form">
      <FormProvider {...f}>
        <Box className="mt-4">
          <CnServices
            study={study.data!}
            conclusionId={cn?.id}
            disabled={access.disabled}
          />
        </Box>
        <Textarea
          {...f.register('riskFactors')}
          label="Факторы риска"
          error={f.formState.errors.riskFactors?.message}
          readOnly={access.disabled}
          className="mt-4"
        />
        <FieldEntranceDoseInmGy disabled={access.disabled} />
        <Textarea
          {...f.register('additionalExam')}
          label="Данные дополнительных исследований"
          error={f.formState.errors.additionalExam?.message}
          readOnly={access.disabled}
          className="mt-4"
        />
        <FieldXRayDensity disabled={access.disabled} />
        <FieldMgQuality disabled={access.disabled} />
        <FieldBirads disabled={access.disabled} />
        <FieldRiskCancer />

        <Flex className="mt-4 items-center gap-4">
          <Box className="w-full">
            <Textarea
              {...f.register('desc')}
              label="Описание"
              error={f.formState.errors.desc?.message}
              readOnly={access.disabled}
            />
          </Box>
          <SelectTemplateBtn
            title="Описание"
            type="DESC"
            onSelect={t => f.setValue('desc', t.value.text)}
          />
        </Flex>

        <div className="flex gap-3">
          <div className="mt-7 w-[224px]">
            <Drawer
              previewImg={cn?.mammo_pic ? `${process.env.S3_URL}/${cn.mammo_pic}` : null}
              bgImg="/assets/app/mammo.png"
              onSave={urlData => f?.setValue('mammoPic', urlData)}
            />
          </div>

          <div className="w-[calc(100%-236px)]">
            <CnAttachments isConclusionOnlyPage={isConclusionOnlyPage} />
            <Textarea
              {...f.register('note')}
              label="Примечание"
              error={f.formState.errors.note?.message}
              classNames={{ input: 'min-h-[136px]' }}
              readOnly={access.disabled}
              className="mt-4"
            />
          </div>
        </div>

        <Flex className="mt-4 items-center gap-4">
          <Box className="w-full">
            <Textarea
              {...f.register('conclusionPlain')}
              label="Заключение"
              error={f.formState.errors.conclusionPlain?.message}
              readOnly={access.disabled}
            />
          </Box>
          <SelectTemplateBtn
            title="Заключение"
            type="CONCLUSION_PLAIN"
            onSelect={t => f.setValue('conclusionPlain', t.value.text)}
          />
        </Flex>

        <CnMammoRecs disabled={access.disabled} />

        <div className="mt-4 flex items-center justify-between gap-3">
          <div className="flex items-center gap-3">
            <DownloadPdfBtn conclusionId={cn?.id} />
            {/* <SaveTemplateBtn study={study.data} /> TODO2: implement */}
          </div>

          {!access.disabled && (
            <SubmitMammoPaidBtn
              onSubmit={onSubmit}
              study={study.data!}
              cnId={cn?.id}
            />
          )}

          {access.canEdit && access.disabled && (
            <Button
              size="xs"
              onClick={() => setWantsToEdit(true)}
            >
              Редактировать
            </Button>
          )}
        </div>
      </FormProvider>
    </Box>
  );
}

const mgQualities = ['BAD', 'GOOD', 'INCORRECT'] as const;
const riskCancer = ['M0', 'M1', 'M2', 'M3', 'M4A', 'M4B', 'M4C', 'M5', 'M6'] as const;

const schema = z.object({
  study_service_ids: z.string().array(),
  riskFactors: z.string(),
  entranceDoseInmGy: z.string(),
  additionalExam: z.string(),
  xRayDensityGland: z.enum(['A', 'B', 'C', 'D']).nullish(),
  mgQuality: z.object({
    right: z.enum(mgQualities),
    left: z.enum(mgQualities),
  }),
  biradsRight: z.string().min(1, 'Это обязательное поле'),
  biradsLeft: z.string().min(1, 'Это обязательное поле'),
  riskCancer: z.object({
    right: z.enum(riskCancer).nullish(),
    left: z.enum(riskCancer).nullish(),
  }),
  desc: z.string(),
  attachments: z
    .object({
      type: z.enum(['CORNERSTONE', 'EXISTING', 'CLIPBOARD']),
      url: z.string(),
      metadata: z.any(),
    })
    .array(),
  mammoPic: z.string(),
  note: z.string(),
  conclusionPlain: z.string(),
  recs: z.string().array(),
});

export type CnMammoPaidForm = z.infer<typeof schema>;

function FieldEntranceDoseInmGy({ disabled }: { disabled: boolean }) {
  const f = useFormContext<CnMammoPaidForm>();

  return (
    <TextInput
      {...f.register('entranceDoseInmGy')}
      label="Эффективная эквивалентная доза (мЗв)"
      type="number"
      readOnly={disabled}
      error={f.formState.errors.entranceDoseInmGy?.message}
      onWheel={e => e.target instanceof HTMLElement && e.target.blur()}
      className="mt-4"
    />
  );
}

function FieldXRayDensity({ disabled }: { disabled: boolean }) {
  const f = useFormContext<CnMammoPaidForm>();
  const mg_quality = f.watch('mgQuality');

  return (
    <Controller
      name="xRayDensityGland"
      control={f.control}
      render={({ field }) => {
        return (
          <Select
            {...field}
            label="Рентгенплотность железы"
            data={(['A', 'B', 'C', 'D'] as const).map(v => ({
              value: v,
              label: `${v}: ${xRayDensityGlandMap[v]}`,
            }))}
            error={f.formState.errors.xRayDensityGland?.message}
            readOnly={disabled || mg_quality.right !== 'GOOD'}
            className="mt-4"
          />
        );
      }}
    />
  );
}

function FieldMgQuality({ disabled }: { disabled: boolean }) {
  const f = useFormContext<CnMammoPaidForm>();

  return (
    <div>
      <div className="mt-4 flex items-center gap-4">
        {(
          [
            { side: 'right', label: 'Качество МГ R:' },
            { side: 'left', label: 'Качество МГ L:' },
          ] as const
        ).map(({ side, label }) => (
          <div
            className="w-1/2"
            key={side}
          >
            <Controller
              name={`mgQuality.${side}`}
              control={f.control}
              render={({ field }) => (
                <Select
                  {...field}
                  label={label}
                  data={(['GOOD', 'BAD', 'INCORRECT'] as const).map(c => ({
                    value: c,
                    label: mapMgQuality[c],
                  }))}
                  error={f.formState.errors.mgQuality?.[side]?.message}
                  readOnly={disabled}
                />
              )}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function FieldBirads({ disabled }: { disabled: boolean }) {
  const f = useFormContext<CnMammoPaidForm>();
  const mg_quality = f.watch('mgQuality');
  const { options } = useGetBiradsLocs();

  return (
    <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2">
      {(
        [
          {
            inputName: 'biradsRight',
            label: 'Результат BI-RADS R:',
            side: 'right',
          },
          {
            inputName: 'biradsLeft',
            label: 'Результат BI-RADS L:',
            side: 'left',
          },
        ] as const
      ).map(({ inputName, label, side }) => {
        return (
          <div key={inputName}>
            <div className="flex flex-col">
              <Controller
                name={inputName}
                control={f.control}
                render={({ field }) => {
                  return (
                    <Select
                      {...field}
                      onChange={v => {
                        field.onChange(v);
                        f.setValue(`riskCancer.${side}`, null);
                      }}
                      label={label}
                      data={options.birads}
                      error={f.formState.errors[inputName]?.message}
                      readOnly={disabled || mg_quality?.[side] !== 'GOOD'}
                    />
                  );
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

function FieldRiskCancer() {
  const { register, watch } = useFormContext<CnMammoPaidForm>();

  const biradsRight = watch('biradsRight');
  const biradsLeft = watch('biradsLeft');
  const biradsLocals = trpc.general.getBiradsLocs.useQuery();

  const getBiradsCategory = (birads: string) => {
    const biradsItem = biradsLocals.data?.birads.find(b => b.id === +birads);
    if (!biradsItem) return [];

    return (
      (
        {
          M3: ['M3'],
          M4: ['M4A', 'M4B', 'M4C'],
          M5: ['M5'],
        } as const
      )[biradsItem.category] || []
    );
  };

  const vals = {
    right: getBiradsCategory(biradsRight),
    left: getBiradsCategory(biradsLeft),
  };

  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
      {(['right', 'left'] as const).map(side => (
        <Flex
          key={side}
          className="flex flex-col gap-2"
        >
          {vals[side].map(r => (
            <Radio
              key={r}
              {...register(`riskCancer.${side}`)}
              value={r}
              label={biradsToRiskCancerMap[r]}
              className="mt-4"
            />
          ))}
        </Flex>
      ))}
    </div>
  );
}

