import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { orthancAxios } from '.';

type MammoInstancesCount = { [key in 'R-CC' | 'L-CC' | 'R-MLO' | 'L-MLO']?: number };

export function useGetMammoInstancesCount(
  { ohif_id }: { ohif_id: string },
  opts: UseQueryOptions<MammoInstancesCount | null>
) {
  return useQuery({
    queryKey: ['instancesCount', ohif_id],
    queryFn: async () => {
      const orthancIds = await orthancAxios.post<{ ID: number }[]>(`tools/lookup`, ohif_id);
      if (!orthancIds.data?.length) {
        return null;
      }

      const study = await orthancAxios.get<{ Series: string[] }>(
        `/studies/${orthancIds.data[0].ID}`
      );
      if (!study.data?.Series.length) {
        return null;
      }

      const countMap: MammoInstancesCount = {};

      for (const seriesID of study.data.Series) {
        const series = await orthancAxios.get<{
          Instances: string[];
          MainDicomTags: { SeriesDescription: keyof MammoInstancesCount };
        }>(`/series/${seriesID}`);

        if (typeof series.data?.MainDicomTags.SeriesDescription !== 'string') {
          continue;
        }

        countMap[series.data.MainDicomTags.SeriesDescription] =
          (countMap[series.data.MainDicomTags.SeriesDescription] || 0) +
          series.data.Instances.length;
      }

      return countMap;
    },
    ...opts,
  });
}
