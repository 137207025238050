import React from 'react';
import {
  Box,
  Flex,
  Highlight,
  Textarea,
  Paper,
  ActionIcon,
  Text,
  ThemeIcon,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useForm } from 'react-hook-form';
import { trpc } from '../app/trpc';
import { notifications } from '@mantine/notifications';
import { modals } from '@mantine/modals';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMemo } from 'react';
import { IconTrash, IconPlus, IconLayoutNavbarCollapse } from '@tabler/icons-react';
import { inferRouterOutputs } from '@trpc/server';
import type { AppRouter } from '../../../medreview/server/src';

interface Props {
  type: 'DESC' | 'CONCLUSION_PLAIN';
  onSelect: (t: Pick<inferRouterOutputs<AppRouter>['template']['getv2'][number], 'value'>) => void;
  defaultTemplates?: string[];
}

export function Templates({ type, onSelect, defaultTemplates }: Props) {
  const templates = trpc.template.getv2.useQuery({ type });

  const fCreate = useForm<z.infer<typeof fCreateZ>>({
    resolver: zodResolver(fCreateZ),
    defaultValues: { text: '' },
  });
  const fSearch = useForm({
    resolver: zodResolver(fSearchZ),
    defaultValues: { search: '' },
  });

  const search = fSearch.watch('search');
  const filteredTempData = useMemo(() => {
    let res: typeof templates.data = [];

    if (!search) {
      res = templates.data || [];
    }

    if (search) {
      res =
        templates.data?.filter(t =>
          new RegExp(search, 'i').test(
            (t.title || '') + (t.tags.join(' ') || '') + (t.value ? JSON.stringify(t.value) : '')
          )
        ) || [];
    }

    return res.map(r => ({ ...r, isDefault: false }) as const);
  }, [search, templates]);

  const mappedDefaultTemplates =
    defaultTemplates?.map(
      dt => ({ id: dt, tags: [], value: { text: dt }, isDefault: true }) as const
    ) || [];

  const createTemplate = trpc.template.createv2.useMutation({
    onSettled: (_, error) => {
      notifications.show({
        message: error ? 'Что-то пошло не так' : 'Шаблон сохранен',
        color: error ? 'red' : 'green',
      });
      templates.refetch();
      fCreate.setValue('text', '');
    },
  });
  const deleteTemplate = trpc.template.delete.useMutation({
    onSettled: (_, error) => {
      notifications.show({
        message: error ? 'Что-то пошло не так' : 'Шаблон удален',
        color: error ? 'red' : 'green',
      });
      templates.refetch();
    },
  });

  return (
    <Box>
      <Box
        className="mt-2"
        component="form"
        onSubmit={fCreate.handleSubmit(data =>
          createTemplate.mutate({
            type,
            value: { text: data.text },
            title: null,
            tags: [],
          })
        )}
      >
        <Flex className="items-center gap-4">
          <Box className="w-11/12">
            <Textarea
              {...fCreate.register('text')}
              placeholder="Значение"
            />
          </Box>

          <ActionIcon
            variant="filled"
            className="p-[2px]"
            component="button"
            type="submit"
            loading={createTemplate.isLoading}
          >
            <IconPlus />
          </ActionIcon>
        </Flex>
      </Box>

      <TextInput
        className="mt-8"
        {...fSearch.register('search')}
        placeholder="Поиск"
      />

      {[...mappedDefaultTemplates, ...filteredTempData].map(t => {
        return (
          <Paper
            key={t.id}
            withBorder
            shadow="lg"
            className="mt-4 cursor-pointer bg-transparent p-3 hover:border-white"
            onClick={() => {
              onSelect(t);
              modals.closeAll();
            }}
          >
            <Flex className="justify-between gap-4">
              <Text className="mt-1">
                <Highlight
                  className="inline"
                  highlight={search}
                >
                  {(t.value as any)?.text || ''}
                </Highlight>
              </Text>

              {t.isDefault === false && (
                <Tooltip label="Удалить шаблон">
                  <ThemeIcon
                    color="red"
                    size="md"
                    className="p-1"
                    onClick={e => {
                      e.stopPropagation();
                      deleteTemplate.mutate({ id: t.id });
                    }}
                  >
                    <IconTrash />
                  </ThemeIcon>
                </Tooltip>
              )}
            </Flex>

            {t.tags.length > 0 && (
              <Text className="mt-1">
                <Text
                  component="span"
                  className="font-bold"
                >
                  Теги:{' '}
                </Text>
                <Highlight
                  className="inline"
                  highlight={search}
                >
                  {t.tags?.join('. ') || ''}
                </Highlight>
              </Text>
            )}
          </Paper>
        );
      })}
    </Box>
  );
}

const fCreateZ = z.object({ text: z.string() });
const fSearchZ = z.object({ search: z.string() });

export function SelectTemplateBtn({
  title,
  type,
  onSelect,
  defaultTemplates,
}: Props & { title?: string }) {
  return (
    <Tooltip label="Выбрать из справочника">
      <ActionIcon
        variant="filled"
        className="p-[2px]"
        onClick={() =>
          modals.open({
            title: title || '',
            centered: true,
            size: 'lg',
            children: (
              <Templates
                type={type}
                onSelect={onSelect}
                defaultTemplates={defaultTemplates}
              />
            ),
          })
        }
      >
        <IconLayoutNavbarCollapse />
      </ActionIcon>
    </Tooltip>
  );
}
