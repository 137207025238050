import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { NewVisitFormType } from '../schema';
import { TextInput, Radio } from '@mantine/core';
import { IMaskInput } from 'react-imask';
import { InputBase, Input } from '@mantine/core';
import { DateInput } from '@mantine/dates';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

export default function NewVisitMainData() {
  const {
    control,
    formState: { errors },
    watch,
    register,
  } = useFormContext<NewVisitFormType>();

  const { t } = useTranslation('Custom');
  const document_type = watch('document_type');

  return (
    <>
      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {t('Document type')}
        </Input.Label>
        <div className="flex flex-wrap gap-4 sm:w-2/3">
          <Radio
            {...register('document_type')}
            label={t('IIN')}
            value="IIN"
          />
          <Radio
            {...register('document_type')}
            label={t('Passport')}
            value="PASSPORT"
          />
        </div>
      </div>

      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {document_type === 'IIN' ? t('IIN') : t('Passport')}
        </Input.Label>
        <TextInput
          {...register('iin')}
          error={errors.iin?.message}
          className="sm:w-2/3"
        />
      </div>

      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {t('Full name')}
        </Input.Label>
        <TextInput
          {...register('fullname')}
          error={errors.fullname?.message}
          className="sm:w-2/3"
        />
      </div>

      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label
          className="sm:w-1/3"
          required
        >
          {t('Birthday')}
        </Input.Label>
        <Controller
          name="bdate"
          control={control}
          render={({ field }) => {
            return (
              <DateInput
                {...field}
                dateParser={input => dayjs(input, 'DD.MM.YYYY').toDate()}
                placeholder="ДД.ММ.ГГГГ"
                size="xs"
                valueFormat="DD.MM.YYYY"
                error={errors[field.name]?.message}
                className="sm:w-2/3"
              />
            );
          }}
        />
      </div>

      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label className="sm:w-1/3">{t('Phone')}</Input.Label>
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <InputBase<any>
              {...field}
              ref={null}
              inputRef={field.ref}
              onChange={() => {}}
              onAccept={(v = '') => field.onChange(v.replace(/[^0-9]/g, ''))}
              component={IMaskInput}
              mask="+7 (000) 000 00 00"
              error={errors.phone?.message ? t('Incorrect phone') : undefined}
              className="sm:w-2/3"
            />
          )}
        />
      </div>

      <div className="mt-4 flex flex-col items-center gap-3 sm:flex-row">
        <Input.Label className="sm:w-1/3">{t('Email')}</Input.Label>
        <TextInput
          {...register('email')}
          error={errors.email?.message ? 'Введите корректный email' : undefined}
          className="sm:w-2/3"
        />
      </div>
    </>
  );
}
